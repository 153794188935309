import React, {
  useRef,
  useState,
  useEffect,
  useMemo,
  useCallback,
} from "react";
import Map from "react-map-gl";
import { Canvas } from "react-three-map";
import { useLoader } from "@react-three/fiber";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader";
import * as THREE from "three";
import { Box, Text, VStack } from "@chakra-ui/react";
import { Html, Environment, Billboard, Line } from "@react-three/drei";
import { useThree } from '@react-three/fiber';

import { ChakraProvider } from "@chakra-ui/react";
import {
  ChevronUpIcon,
  ChevronDownIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
} from "@chakra-ui/icons";
import { HStack, Grid, GridItem, Button } from "@chakra-ui/react";
import { easeCubic } from "d3-ease";
import Sidebar from "./Sidebar";
import mapboxgl from "mapbox-gl";
import { fetchRequest, getBuildingAttribute } from "../utils";
import BuildingFilterBar from "./BuildingFilterBar";
import { predefinedColors } from "../hook/useQuery";

const ZOOM_LEVEL = 18;
const INITIAL_VIEW_STATE = {
  longitude: -72.28949345934565,
  latitude: 43.70438686950007,
  zoom: 17.8,
  pitch: 70.9,
  bearing: -10.5,
};

const initialBuildingData = {
  buildings: [
    {
      id: "2d4ac03e-6858-4f6d-8b4a-79fb50002864",
      name: "109-111 Rosedale Circle",
      address: null,
      model: "/building-exports/bakerberrylibrary3.glb",
      attributes: [
        { key: "latitude", value: "43.730336869500036" },
        { key: "longitude", value: "-72.270673459345616" },
        { key: "occupancy", value: "528" },
        { key: "roomTypes", value: "Singles, Doubles, Triples" },
        { key: "floorLayout", value: "Single & Mixed Gender Floors" },
        { key: "academicLevel", value: "1st Year, Returning Students" },
        { key: "bathroomType", value: "Communal, Gendered" },
        { key: "kitchen", value: "One on ground floor" },
        { key: "storage", value: "Indoor Bike Storage" },
      ],
      features: [],
    },
  ],
};
//Query Params using window object
// const getQueryParams = () => {
//   const params = new URLSearchParams(window.location.search);
//   const orgId = parseFloat(params.get("orgId"));
//   return orgId;
// };

// console.log("1111", getQueryParams());

const convertLatLngToVector3 = (longitude, latitude) => {
  const mapCenter = mapboxgl.MercatorCoordinate.fromLngLat([
    INITIAL_VIEW_STATE.longitude,
    INITIAL_VIEW_STATE.latitude,
  ]);
  const position = mapboxgl.MercatorCoordinate.fromLngLat([
    longitude,
    latitude,
  ]);

  const x = (position.x - mapCenter.x) * 1000000;
  const z = (position.y - mapCenter.y) * 1000000; // Swap Y to Z
  const y = 0; // Altitude remains the same

  return new THREE.Vector3(x, y, z);
};

function getCurrentSubdomain() {
  let hostname = window.location.hostname;
  hostname = hostname.replace(/^https?:\/\//, "");

  const parts = hostname.split(".");
  if (
    hostname === "localhost" ||
    process.env.REACT_APP_ACTIVE_ENV === "local"
  ) {
    return "wake-forest-staging";
  }

  if (parts.length > 2) {
    return parts.slice(0, parts.length - 2).join(".");
  }

  return null;
}

const ArrowButton = ({ onClick, icon, label }) => (
  <Button size="sm" onClick={onClick} aria-label={label}>
    {icon}
  </Button>
);

const DebugTool = ({
  buildingPositions,
  adjustPosition,
  selectedBuildingName,
}) => {
  const formatCoordinate = (value) => {
    if (typeof value === "number") {
      return value.toFixed(15);
    } else if (value !== undefined) {
      return Number(value).toFixed(15);
    }
    return "N/A";
  };

  const selectedPosition = selectedBuildingName
    ? buildingPositions[selectedBuildingName]
    : null;

  return (
    <Box
      position="absolute"
      bottom="20px"
      right="20px"
      bg="white"
      p={4}
      borderRadius="md"
      boxShadow="lg"
    >
      <VStack align="start" spacing={2}>
        <Text>Current Model Position:</Text>
        <Text>
          Latitude:
          {selectedPosition
            ? formatCoordinate(selectedPosition.latitude)
            : "N/A"}
        </Text>
        <Text>
          Longitude:
          {selectedPosition
            ? formatCoordinate(selectedPosition.longitude)
            : "N/A"}
        </Text>
        <Text>Adjust Position:</Text>
        <HStack justify="space-between" align="center">
          {/* Fine adjustment */}
          <VStack>
            <Text fontSize="md">Fine (0.00001)</Text>
            <Grid templateColumns="repeat(3, 1fr)" gap={1}>
              <GridItem colStart={2}>
                <ArrowButton
                  onClick={() => adjustPosition("latitude", 0.0001)}
                  icon={<ChevronUpIcon />}
                  label="N"
                />
              </GridItem>
              <GridItem colStart={1} rowStart={2}>
                <ArrowButton
                  onClick={() => adjustPosition("longitude", -0.0001)}
                  icon={<ChevronLeftIcon />}
                  label="W"
                />
              </GridItem>
              <GridItem colStart={3} rowStart={2}>
                <ArrowButton
                  onClick={() => adjustPosition("longitude", 0.0001)}
                  icon={<ChevronRightIcon />}
                  label="E"
                />
              </GridItem>
              <GridItem colStart={2} rowStart={3}>
                <ArrowButton
                  onClick={() => adjustPosition("latitude", -0.0001)}
                  icon={<ChevronDownIcon />}
                  label="S"
                />
              </GridItem>
            </Grid>
          </VStack>
          {/* Coarse adjustment */}
          <VStack>
            <Text fontSize="md">Coarse (0.0001)</Text>
            <Grid templateColumns="repeat(3, 1fr)" gap={1}>
              <GridItem colStart={2}>
                <ArrowButton
                  onClick={() => adjustPosition("latitude", 0.001)}
                  icon={<ChevronUpIcon />}
                  label="N"
                />
              </GridItem>
              <GridItem colStart={1} rowStart={2}>
                <ArrowButton
                  onClick={() => adjustPosition("longitude", -0.001)}
                  icon={<ChevronLeftIcon />}
                  label="W"
                />
              </GridItem>
              <GridItem colStart={3} rowStart={2}>
                <ArrowButton
                  onClick={() => adjustPosition("longitude", 0.001)}
                  icon={<ChevronRightIcon />}
                  label="E"
                />
              </GridItem>
              <GridItem colStart={2} rowStart={3}>
                <ArrowButton
                  onClick={() => adjustPosition("latitude", -0.001)}
                  icon={<ChevronDownIcon />}
                  label="S"
                />
              </GridItem>
            </Grid>
          </VStack>
        </HStack>
      </VStack>
    </Box>
  );
};

function buildingTitleColor(filterType, buildingFilters, buildingColors) {
  const lowerCaseFilter = filterType?.toLowerCase();
  if (buildingFilters?.includes(lowerCaseFilter)) {
    return buildingColors[lowerCaseFilter.toUpperCase()];
  }
  return null;
}

const BuildingModel = ({
  building,
  model,
  handleBuildingClick,
  setSelectedPart,
  position,
  selectedPart,
  buildingFilters,
  color,
}) => {
  const { camera } = useThree();
  const modelRef = useRef();
  const buildingPosition = useRef(new THREE.Vector3());
  const [height, setHeight] = useState(0);
  const [isSelected, setIsSelected] = useState(false);
  const [scale, setScale] = useState(1);

  const { adjustedModel } = useMemo(() => {
    if (model) {
      const buildingMesh = model.scene.children[0];
      const buildingClone = buildingMesh.clone();

      // Center model
      const bbox = new THREE.Box3().setFromObject(buildingClone);
      const center = bbox.getCenter(new THREE.Vector3());
      buildingClone.position.sub(center);

      // Adjust Y position
      let minY = Infinity;
      buildingClone.traverse((child) => {
        if (child.isMesh) {
          child.geometry.computeBoundingBox();
          minY = Math.min(minY, child.geometry.boundingBox.min.y);
        }
      });
      buildingClone.position.y -= minY;

      // Scale and adjust material
      const buildingScale = building.scale || 1;
      buildingClone.scale.set(buildingScale, buildingScale, buildingScale);
      buildingClone.traverse((child) => {
        if (child.isMesh) {
          child.material.side = THREE.DoubleSide;
        }
      });

      // Compute height
      const adjustedBbox = new THREE.Box3().setFromObject(buildingClone);
      setHeight((adjustedBbox.max.y - adjustedBbox.min.y) * buildingScale);

      const adjustedModel = new THREE.Object3D();
      adjustedModel.add(buildingClone);

      return { adjustedModel };
    }
    return { adjustedModel: null };
  }, [building, model]);

  useEffect(() => {
    setIsSelected(
      !!building &&
      (selectedPart === building.name ||
        buildingFilters.includes(building.type?.toLowerCase() || ''))
    );
  }, [buildingFilters, selectedPart]);

  useEffect(() => {
    if (modelRef.current) {
      modelRef.current.updateMatrixWorld();
      modelRef.current.getWorldPosition(buildingPosition.current);
    }
  }, [position]);

  // Adjust scale based on camera distance (for responsive text size)
  useEffect(() => {
    const distance = camera.position.distanceTo(
      new THREE.Vector3(position.x, position.y, position.z)
    );
    const newScale = Math.max(0.4, Math.min(1.5, 10 / distance)); // Clamp scale within a reasonable range
    setScale(newScale);
  }, [camera.position, position]);

  // Highlight on selection
  useEffect(() => {
    if (modelRef.current) {
      modelRef.current.traverse((child) => {
        if (child.isMesh) {
          if (isSelected) {
            const highlightColor = new THREE.Color(color || '#FFFA00');
            child.material.emissive.set(highlightColor);
            child.material.emissiveIntensity = 0.5;
          } else {
            child.material.emissive.set(0x000000);
            child.material.emissiveIntensity = 0;
          }
        }
      });
    }
  }, [isSelected, color]);

  if (!adjustedModel) return null;

  const handleClick = (event) => {
    event.stopPropagation();
    handleBuildingClick(building, buildingPosition.current);
    setSelectedPart(building.name);
  };

  return (
    <>
      <primitive
        ref={modelRef}
        object={adjustedModel}
        position={position}
        onClick={handleClick}
        onPointerOver={() => (document.body.style.cursor = 'pointer')}
        onPointerOut={() => (document.body.style.cursor = 'default')}
      />

      {height > 0 && (
        <Billboard
          position={[
            position.x,
            position.y + height + 0.5 * scale,
            position.z,
          ]}
        >
          <Html
            zIndexRange={[0, 1]}
            style={{
              pointerEvents: 'auto',
              cursor: 'pointer',
              transform: 'translate3d(-50%, -100%, 0)',
              opacity: 1,
              transition: 'opacity 0.2s ease',
              transformOrigin: 'center',
            }}
          >
            <div
              onClick={(e) => {
                e.stopPropagation();
                handleBuildingClick(building, position);
                setSelectedPart(building.name);
              }}
              style={{
                position: 'relative',
                width: `${150 * (isSelected ? 1.5 * scale : scale)}px`,
                height: `${40 * (isSelected ? 1.5 * scale : scale)}px`,
                minWidth: "fit-content",
                width: "max-content",
                backgroundColor: color || "rgba(69, 69, 69, 0.7)",
                opacity: 0.7,
                borderRadius: `${6 * (isSelected ? 1.5 * scale : scale)}px`,
                border: `1px solid ${selectedPart === building.name ? '#389E0D' : 'rgba(255, 255, 255, 0.2)'
                  }`,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                boxShadow: `0 ${4 * (isSelected ? 1.5 * scale : scale)}px ${12 * (isSelected ? 1.5 * scale : scale)
                  }px rgba(0,0,0,0.2)`,
                padding: `${4 * (isSelected ? 1.5 * scale : scale)}px ${12 * (isSelected ? 1.5 * scale : scale)
                  }px`,
                fontSize: `${18 * (isSelected ? 1.5 * scale : scale)}px`,
                fontWeight: '500',
                color: '#fff',
                textAlign: 'center',
                textShadow: `0 ${2 * (isSelected ? 1.5 * scale : scale)}px ${6 * (isSelected ? 1.5 * scale : scale)
                  }px rgba(0, 0, 0, 0.6)`,
                letterSpacing: '0.4px',
              }}
            >
              {building.name}

              {/* Arrow */}
              <div
                style={{
                  position: 'absolute',
                  bottom: `-8px`,
                  left: '50%',
                  transform: 'translateX(-50%)',
                  width: `${30 * (isSelected ? 1.5 * scale : scale)}px`,
                  height: '0',
                  borderLeft: `${15 * (isSelected ? 1.5 * scale : scale)}px solid transparent`,
                  borderRight: `${15 * (isSelected ? 1.5 * scale : scale)}px solid transparent`,
                  borderTop: `${15 * (isSelected ? 1.5 * scale : scale)}px solid ${color
                      ? color // Use color if provided
                      : selectedPart === building.name
                        ? 'rgba(56, 158, 13, 0.9)'
                        : 'rgba(162, 19, 19, 0.7)'
                    }`,
                }}
              />
            </div>

          </Html>
        </Billboard>
      )}
    </>
  );
};

const CanvasContent = ({
  buildings,
  models,
  handleBuildingClick,
  setSelectedPart,
  selectedPart,
  buildingPositions,
  buildingFilters,
  buildingColors,
}) => {
  return (
    <>
      <Environment preset="forest" />
      <ambientLight intensity={0.5} />
      <directionalLight
        position={[50, 50, 50]}
        intensity={1}
        color="#ffffff"
        castShadow={true}
        shadow-mapSize-width={1024}
        shadow-mapSize-height={1024}
        shadow-camera-far={200}
        shadow-camera-near={0.5}
        shadow-camera-left={-50}
        shadow-camera-right={50}
        shadow-camera-top={50}
        shadow-camera-bottom={-50}
      />
      {buildings.map((building, index) => {
        let position;
        let color;
        const isHighlighted = building.name === selectedPart; // Check if building is selected for highlight
        if (
          buildingPositions?.[building.name]?.longitude &&
          buildingPositions?.[building.name]?.latitude
        ) {
          position = convertLatLngToVector3(
            buildingPositions?.[building.name]?.longitude,
            buildingPositions?.[building.name]?.latitude
          );
          color = buildingTitleColor(
            building.type?.trim(),
            buildingFilters,
            buildingColors,
            buildings
          );
          return (
            <BuildingModel
              key={building.id}
              building={building}
              model={models[index]}
              handleBuildingClick={handleBuildingClick}
              setSelectedPart={setSelectedPart}
              position={position}
              selectedPart={selectedPart}
              buildingFilters={buildingFilters}
              color={color}
              highlighted={isHighlighted}
            />
          );
        }
        return null;
      })}
    </>
  );
};

const getBuildingPositions = (buildings) => {
  if (!buildings) return {};

  const positions = {};
  buildings.forEach((building) => {
    const latitude = getBuildingAttribute(
      buildings,
      building?.name,
      "latitude"
    );
    const longitude = getBuildingAttribute(
      buildings,
      building?.name,
      "longitude"
    );
    positions[building.name] = {
      latitude: Number(latitude),
      longitude: Number(longitude),
    };
  });

  return positions;
};

const convertToTitleCase = (str) => {
  if (!str) return "";
  return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
};

const R3fMap = () => {
  const mapRef = useRef(null);
  const [mapStyle, setMapStyle] = useState(
    "mapbox://styles/zakroomie/cm1f8c40400sh01nw7ydv2hwa"
  );
  const [buildings, setBuildings] = useState(initialBuildingData.buildings);
  const [selectedRoom, setSelectedRoom] = useState(null);
  const [selectedPart, setSelectedPart] = useState(null);
  const [showDebugTool, setShowDebugTool] = useState(false);
  const [buildingPositions, setBuildingPositions] = useState(
    getBuildingPositions(initialBuildingData?.buildings)
  );
  const [showBuildingFilters, setShowBuildingFilters] = useState(false);
  const [universityDetails, setUniversityDetails] = useState(null);
  const [buildingColors, setBuildingColors] = useState({});
  const [buildingFilters, setBuildingFilters] = useState([]);
  const buildingTypes = Object.keys(buildingColors)?.map((type) =>
    convertToTitleCase(type)
  );
  const showBuildingLayers = Boolean(buildingTypes?.length);

  console.log({ selectedPart });

  //TODO : Future Implementation
  const handleBuildingFilters = useCallback(
    (selectedBuildingFilter) => {
      setBuildingFilters((prevBuildingFilters) => {
        const updatedBuildingFilters = [...prevBuildingFilters];

        if (updatedBuildingFilters.includes(selectedBuildingFilter)) {
          return updatedBuildingFilters.filter(
            (buildingType) => buildingType !== selectedBuildingFilter
          );
        } else {
          return [...updatedBuildingFilters, selectedBuildingFilter];
        }
      });
    },

    [setBuildingFilters]
  );

  console.log({ buildingFilters });

  const filteredBuildings = useMemo(() => {
    if (buildingFilters.length === 0) {
      return buildings;
    }
    return buildings.filter((building) =>
      buildingFilters.includes(building.type.toLowerCase())
    );
  }, [buildings, buildingFilters]);

  const assignColorsToBuildingTypes = (buildings) => {
    const typeColors = {};
    let colorIndex = 0;
    buildings.forEach((building) => {
      const type = building.type.trim();
      if (type && !typeColors[type]) {
        typeColors[type] =
          predefinedColors[colorIndex % predefinedColors.length];
        colorIndex++;
      }
    });
    setBuildingColors(typeColors);
  };

  useEffect(() => {
    async function fetchBuildings() {
      try {
        // Hardcode the full URL for production
        const url = "https://staging-server.roomie.com/services-1/roomie/university-map-details?origin=wake-forest-staging";
        const data = await fetchRequest(url, "GET");

        let buildings = data?.buildings?.filter(
          (building) => building?.attributes?.length > 0 && building?.model
        );

        setBuildings(buildings);
        setUniversityDetails({
          name: data?.name,
          friendlyName: data?.friendlyName,
          logo: data?.logo,
        });
        const buildingPositionsInitial = getBuildingPositions(buildings);
        setBuildingPositions(buildingPositionsInitial);
        assignColorsToBuildingTypes(buildings);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    }

    fetchBuildings();
  }, []);

  useEffect(() => {
    if (showDebugTool) {
      setMapStyle("mapbox://styles/mapbox/standard");
    } else {
      setMapStyle("mapbox://styles/zakroomie/cm1f8c40400sh01nw7ydv2hwa");
    }
  }, [showDebugTool]);

  console.log({ buildings });

  const models = useLoader(
    GLTFLoader,
    buildings?.map((building) => building?.model)
  );

  useEffect(() => {
    console.log("111", { models });
  }, [models]);

  console.log({ models });

  useEffect(() => {
    const handleKeyPress = (event) => {
      if (event.key === "p" || event.key === "P") {
        setShowDebugTool((prev) => !prev);
      }
    };

    window.addEventListener("keydown", handleKeyPress);

    return () => {
      window.removeEventListener("keydown", handleKeyPress);
    };
  }, []);

  const adjustPosition = (coordinate, adjustment) => {
    setBuildingPositions((prevPositions) => {
      const buildingName = selectedPart;
      if (!buildingName) return prevPositions;

      const currentValue = Number(prevPositions[buildingName][coordinate]);
      const adjustmentValue = Number(adjustment);

      if (isNaN(currentValue) || isNaN(adjustmentValue)) {
        console.error("Invalid value encountered:", {
          coordinate,
          currentValue,
          adjustment,
        });
        return prevPositions;
      }

      const newValue = parseFloat((currentValue + adjustmentValue).toFixed(15));

      return {
        ...prevPositions,
        [buildingName]: {
          ...prevPositions[buildingName],
          [coordinate]: newValue,
        },
      };
    });
  };

  const handleRoomDetails = (room) => {
    if (room) {
      setSelectedRoom(room);
      return;
    }
    setSelectedRoom(null);
  };

  const convertVector3ToLatLng = (vector3) => {
    const mapCenter = mapboxgl.MercatorCoordinate.fromLngLat([
      INITIAL_VIEW_STATE.longitude,
      INITIAL_VIEW_STATE.latitude,
    ]);

    const scale = 28000000;

    const positionX = vector3.x / scale + mapCenter.x;
    const positionY = vector3.z / scale + mapCenter.y;

    const mercatorCoordinate = new mapboxgl.MercatorCoordinate(
      positionX,
      positionY
    );

    const lngLat = mercatorCoordinate.toLngLat();
    return [lngLat.lng, lngLat.lat];
  };

  const flyToLocation = (longitude, latitude) => {
    const map = mapRef.current?.getMap();
    if (map && longitude && latitude) {
      map.flyTo({
        center: [longitude, latitude],
        zoom: ZOOM_LEVEL,
        speed: 0.8,
        curve: 1.42,
        easing: easeCubic,
        essential: true,
      });
    }
  };

  // const handleBuildingClick = (building, modelPosition) => {
  //   setSelectedPart(building.name);
  //   const attributes = building?.attributes;
  //   if (attributes?.length) {
  //     const position = convertLatLngToVector3(
  //       buildingPositions?.[building.name]?.longitude,
  //       buildingPositions?.[building.name]?.latitude
  //     );
  //     const [long, lat] = convertVector3ToLatLng(position);
  //     flyToLocation(long, lat);
  //   }
  //   if (modelPosition) {
  //     // Convert Three.js position to geographic coordinates
  //     const [longitude, latitude] = convertVector3ToLatLng(modelPosition);
  //     console.log("Flying to location", longitude, latitude);
  //     flyToLocation(longitude, latitude);
  //   }
  // };


  const handleBuildingClick = (building, modelPosition) => {
    setSelectedPart(building.name);

    const attributes = building?.attributes;

    if (attributes?.length) {
      const position = convertLatLngToVector3(
        buildingPositions?.[building.name]?.longitude,
        buildingPositions?.[building.name]?.latitude
      );

      const [long, lat] = convertVector3ToLatLng(position);
      flyToLocation(long, lat);
    }

    if (modelPosition) {
      // Convert Three.js position to geographic coordinates
      const [longitude, latitude] = convertVector3ToLatLng(modelPosition);
      console.log("Flying to location", longitude, latitude);
      flyToLocation(longitude, latitude);
    }

    // Update the models using the helper function
  };

  const handleBackToList = () => {
    setSelectedPart(null);
    const map = mapRef.current?.getMap();
    if (map) {
      map.flyTo({
        ...INITIAL_VIEW_STATE,
        speed: 0.8,
        curve: 1.42,
        easing: easeCubic,
        essential: true,
      });
    }
    setBuildings(buildings);
  };

  console.log({ buildings });

  const dartmouthBounds = [
    [-72.298, 43.703],
    [-72.281, 43.712],
  ];
  //TODO : Future Implementation
  function handleBuildingTypeFilterTextColor(filter) {
    const isSelected = buildingFilters.includes(filter);
    if (!isSelected) {
      return "#272C3A";
    }
    return buildingColors[filter.toUpperCase()];
  }

  const handleToggle = () => {
    if (showBuildingFilters) {
      setBuildingFilters([]);
    }
    setShowBuildingFilters(!showBuildingFilters);
  };

  return (
    <ChakraProvider>
      <div style={{ position: "relative", width: "100%", height: "100%" }}>
        <Map
          ref={mapRef}
          antialias
          initialViewState={INITIAL_VIEW_STATE}
          style={{ width: "100%", height: "100%" }}
          mapStyle={mapStyle}
          mapboxAccessToken="pk.eyJ1IjoiemFrcm9vbWllIiwiYSI6ImNsenZsZjV6dTA4cHUycXByaTVycnJkMjcifQ.b5eA5N9VYdJhRFlcT5fytw"
          maxBounds={dartmouthBounds}
        >
          <Canvas
            latitude={INITIAL_VIEW_STATE.latitude}
            longitude={INITIAL_VIEW_STATE.longitude}
          >
            <CanvasContent
              buildings={buildings}
              models={models}
              handleBuildingClick={handleBuildingClick}
              setSelectedPart={setSelectedPart}
              selectedPart={selectedPart}
              buildingPositions={buildingPositions}
              buildingFilters={buildingFilters}
              buildingColors={buildingColors}
            />
          </Canvas>
        </Map>
        {showBuildingLayers && (
          <BuildingFilterBar
            handleBuildingFilters={handleBuildingFilters}
            handleBuildingTypeFilterTextColor={
              handleBuildingTypeFilterTextColor
            }
            showBuildingFilters={showBuildingFilters}
            setShowBuildingFilters={setShowBuildingFilters}
            buildingTypes={buildingTypes}
            handleToggle={handleToggle}
            buildingFilters={buildingFilters}
          />
        )}
        <Sidebar
          selectedPart={selectedPart}
          setSelectedPart={setSelectedPart}
          buildings={filteredBuildings}
          handleBuildingClick={handleBuildingClick}
          handleBackToList={handleBackToList}
          selectedRoom={selectedRoom}
          handleRoomDetails={handleRoomDetails}
          universityDetails={universityDetails}
        />

        {showDebugTool && selectedPart && (
          <DebugTool
            buildingPositions={buildingPositions}
            adjustPosition={adjustPosition}
            selectedBuildingName={selectedPart}
          />
        )}
      </div>
    </ChakraProvider>
  );
};

export default R3fMap;
