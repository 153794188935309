import React from "react";
import { useLocation } from "react-router-dom";
export function useQuery() {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
}

export const predefinedColors = [
  "#FF1200", // Red
  "#011AFF", // Blue
  "#FF8300", // Orange
  '#008B8B',
  '#009926',
  "#007FFF", // Azure
  "#0000FF", // Bright Blue
  "#7F00FF", // Violet
  "#FF00FF", // Magenta
  "#FF007F", // Rose
  "#FF1493", // Deep Pink
  "#FF4500", // Orange Red
  "#FFD700",
];
