import { Flex, Button, Image, Text } from "@chakra-ui/react";
import Layer from "../Images/Layer.svg";
import Cross from "../Images/Cross.svg";
import tinycolor from 'tinycolor2';



function BuildingFilterBar({
  handleBuildingFilters,
  handleBuildingTypeFilterTextColor,
  showBuildingFilters,
  buildingTypes,
  handleToggle,
  buildingFilters,
}) {
  const isBuildingFiltersApplied = buildingFilters?.length > 0;
  return (
    <Flex
      cursor="pointer"
      position="absolute"
      top={0}
      right={0}
      m={3}
      width="fit-content"
      height="44px"
      bg="#FFFFFF"
      boxShadow="0px 2px 6px 0px #00000033"
      backdropFilter="blur(10px)"
      color="rgba(82, 94, 112, 1)"
      py={4}
      px={0}
      borderRadius="100px"
      border="1px solid "
      borderColor="#FFFFFF"
      alignItems="center"
      p={2}
      gap={2}
    >
{showBuildingFilters
  ? buildingTypes?.map((filter) => (
      <Button
        key={filter}
        size="sm"
        variant="outline"
        onClick={() => handleBuildingFilters(filter.toLowerCase())}
        border="none"
        color={
          tinycolor(handleBuildingTypeFilterTextColor(filter.toLowerCase()))
            .darken(10)
            .desaturate(10)
            .toHexString()
        }
      >
        {filter}
      </Button>
    ))
  : null}



      <Flex alignItems="center" ml="auto" onClick={handleToggle}>
        <Image
          src={Layer}
          alt={"Building Layer"}
          borderRadius="md"
          width={4}
          height={4}
          objectFit="cover"
          mr={1}
        />
        <Text fontSize="sm" mr={1}>
          {isBuildingFiltersApplied ? "Clear" : "Layers"}
        </Text>
        {isBuildingFiltersApplied && (
          <Image
            src={Cross}
            alt={"Building Cross"}
            borderRadius="md"
            width={3}
            height={3}
            objectFit="cover"
            mr={1}
          />
        )}
      </Flex>
    </Flex>
  );
}

export default BuildingFilterBar;
