import React, { useState } from "react";
import {
  Box,
  VStack,
  Text,
  Image,
  Button,
  HStack,
  Flex,
  TabPanel,
  Tabs,
  TabList,
  Tab,
  TabPanels,
  Accordion,
  Divider,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
} from "@chakra-ui/react";
import { ChevronDownIcon, ChevronRightIcon } from "@chakra-ui/icons";
import {
  fetchRequest,
  getBuildingAttribute,
  getRoomAttributes,
} from "../utils";
import SharedModal from "./SharedModal";
import ImageCarousel from "./ImageCaurosel";
import ThreeDRoom from "../Images/ThreeDRoom.svg";
import RoomBlueSmall from "../Images/RoomBlueSmall.svg";
import ExpandAlt from "../Images/ExpandAlt.svg";
import BuildingInfo from "./BuildingInfo";

const Sidebar = ({
  selectedPart,
  buildings,
  handleBuildingClick,
  handleBackToList,
  selectedRoom,
  handleRoomDetails,
  universityDetails,
}) => {
  const [roomsInfo, setRoomInfo] = useState(null);
  const [expandedSections, setExpandedSections] = useState([]);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const {
    isOpen: isOpenVideo,
    onOpen: onOpenVideo,
    onClose: onCloseVideo,
  } = useDisclosure();
  
  const {
    isOpen: isOpenVirtualTour,
    onOpen: onOpenVirtualTour,
    onClose: onCloseVirtualTour,
  } = useDisclosure();
  const selectedBuildingId = buildings.find((b) => b.name === selectedPart)?.id;
  const building = buildings?.find((b) => b.id === selectedBuildingId);
  const configId = selectedRoom?.id || "34b4dddf-8241-429a-909d-23364ee01020";
  const templateTokenUrl = selectedRoom?.templateToken || "GU20241d07";
  const dimensionUrl = selectedRoom?.dimensions?.[0];
  const isRoomInfoDisable = building?.type !== "RESIDENTIAL";

  async function getRoomInfo() {
    try {
      if (!selectedBuildingId) {
        return null;
      }
      const endpoint = `/buildings/${selectedBuildingId}/rooms`;
      const rooms = await fetchRequest(
        `${process.env.REACT_APP_API_BASE_URL}${endpoint}`,
        "GET"
      );
      setRoomInfo(rooms);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  }

  function groupData() {
    const group = {};
    group[building?.name] = {};
    for (let key in building) {
      if (Array.isArray(building[key])) {
        group[building.name][key] = building[key];
      }
    }
    return group;
  }

  function camelCaseToTitleCase(text) {
    return text
      .replace(/([A-Z])/g, " $1")
      .replace(/^./, (str) => str.toUpperCase());
  }

  const buildingAttributeGroup = groupData();

  // Access Categories
  const categories = buildingAttributeGroup[building?.name]?.categories;
  // Access Categories

  // Photos, Videos, and Virtual Tour Access
  const photos =
    categories && categories?.filter((cat) => cat?.category === "Photos");
  const imageUrls = photos && photos[0]?.items?.map((photo) => photo.value);
  const videos =
    categories && categories?.filter((cat) => cat?.category === "Videos");
  const videoUrl = videos && videos[0]?.items?.map((video) => video.value);
  const virtualTours =
    categories && categories?.filter((cat) => cat?.category === "Virtual Tour");
  const virtualTourUrl = virtualTours && virtualTours[0]?.items?.map((tour) => tour.value)[0] || "https://my.matterport.com/show/?m=j4affnP1Ack";
  // Photos, Videos, and Virtual Tour Access

  // Access Attributes
  let attributeList = buildingAttributeGroup[building?.name]?.attributes;

  let attributes = attributeList?.filter(
    (item) =>
      item.key.toLowerCase() !== "latitude" &&
      item.key.toLowerCase() !== "longitude" &&
      item.key.toLowerCase() !== "imageUrl"
  );

  attributes =
    attributes &&
    attributes.map(({ label, key, value }) => ({
      label: label || camelCaseToTitleCase(key),
      value,
    }));
  // Access Attributes

  function handleExpandSections(roomId) {
    setExpandedSections((prevExpandedSections) => {
      const updatedSections = [...prevExpandedSections];

      if (updatedSections.includes(roomId)) {
        return updatedSections.filter((id) => id !== roomId);
      } else {
        return [...updatedSections, roomId];
      }
    });
  }

  const isRoomDetailsOpen = selectedRoom && Object.keys(selectedRoom)?.length;
  return (
    <Box
      position="absolute"
      top={0}
      left={0}
      m={3}
      width="390px"
      maxH="90vh"
      minH="fit-content"
      bg="#f7faf5"
      color="rgba(82, 94, 112, 1)"
      py={4}
      px={0}
      borderRadius="10"
      overflowY="auto"
      boxShadow="0px 0px 10px 0px rgba(0, 0, 0, 0.2)"
      sx={{
        "::-webkit-scrollbar": {
          display: "none",
        },
        "-ms-overflow-style": "none",
        "scrollbar-width": "none",
      }}
    >
      {!selectedPart ? (
        <VStack align="start">
          <HStack spacing={1} alignItems="center" p={1}>
            <Image src={universityDetails?.logo} width="50px" />
            <VStack align="start" spacing={0}>
              <Text fontSize="md" fontWeight="bold">
                {universityDetails?.name}
              </Text>
              <Text fontSize="md" color="gray.400">
                {universityDetails?.friendlyName}
              </Text>
            </VStack>
          </HStack>

          {buildings &&
            buildings?.map((building, index) => (
              <Box
                key={index}
                onClick={() => handleBuildingClick(building)}
                cursor="pointer"
                width="full"
                borderBottom={index === buildings.length - 1 ? "none" : "1px"}
                borderTop={index === 0 ? "1px" : "none"}
                borderColor="rgba(235, 241, 245, 1)"
                p={2}
                borderRadius="md"
              >
                <HStack spacing={4} justifyContent="space-between">
                  <HStack spacing={4}>
                    <Image
                      src={getBuildingAttribute(
                        buildings,
                        building?.name,
                        "imageUrl"
                      )}
                      alt={building?.name}
                      borderRadius="md"
                      width="60px"
                      height="60px"
                      objectFit="cover"
                    />
                    <VStack align="start" spacing={0}>
                      <Text fontSize="sm" color="gray.500">
                        {getBuildingAttribute(
                          buildings,
                          building?.name,
                          "location"
                        )}
                      </Text>
                      <Text
                        fontSize="md"
                        fontWeight="bold"
                        isTruncated
                        maxWidth="200px"
                        title={building?.name}
                      >
                        {building?.name}
                      </Text>
                      <Text fontSize="sm" color="gray.500">
                        {getBuildingAttribute(
                          buildings,
                          building?.name,
                          "theme"
                        )}
                      </Text>
                    </VStack>
                  </HStack>
                  <ChevronRightIcon w={6} h={6} color="gray.500" />
                </HStack>
              </Box>
            ))}
                      <Box width="100%" px={2} py={2}>
      <Button
        as="a"
        href="/buildings-comparison.html"
        leftIcon={
          <Image 
            src="/compareicon.svg"
            alt="Compare"
            width="25px"
            height="25px"
          />
        }
        width="100%"
        colorScheme="blue"
        backgroundColor="#00539B"
        color="white"
        borderRadius="md"
        size="md"
      >
        Compare Buildings
      </Button>
    </Box>
        </VStack>
      ) : (
        <VStack align="start" spacing={4} width="95%" margin="auto">
          <HStack width="100%" justifyContent="space-between" p={2}>
            <Button
              p={0}
              onClick={handleBackToList}
              leftIcon={
                <svg
                  width="50px"
                  height="50px"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  strokeWidth="1"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                >
                  <path d="M15 18l-6-6 6-6" />
                </svg>
              }
              iconSpacing={-5}
              colorScheme="teal"
              variant="ghost"
            />
            <Flex alignItems="center" justifyContent="center">
              <Box>
                <Text fontSize="sm" color="gray.500">
                  Main Campus
                </Text>
                <Text fontSize="md" fontWeight="bold">
                  {selectedPart}
                </Text>
              </Box>
            </Flex>
            <Image
              src={getBuildingAttribute(buildings, building?.name, "imageUrl")}
              boxSize="100px"
              borderRadius="md"
              objectFit="cover"
            />
          </HStack>

          <Tabs variant="unstyled" width="100%" isFitted>
            <TabList borderRadius="12px" p={2} border="1px solid silver">
              <Tab
                _selected={{ bg: "rgba(91, 96, 106, 1)", color: "white" }}
                bg="#f7faf5"
                color="gray.500"
                borderRadius="md"
              >
                Building Info
              </Tab>
              <Tab
                _selected={{ bg: "rgba(91, 96, 106, 1)", color: "white" }}
                bg="#f7faf5"
                color="gray.500"
                borderRadius="md"
                onClick={getRoomInfo}
                isDisabled={isRoomInfoDisable}
              >
                Room Info
              </Tab>
            </TabList>

            <TabPanels>
              {/* Building Info Tab */}
              <TabPanel h="52vh" maxH="52vh" overflow="auto">
                <Text>
                  {buildings &&
                    buildings?.find((b) => b.name === selectedPart)
                      ?.description}
                </Text>
                <Box borderRadius="md">
                  {attributes &&
                    attributes?.map((item, index, arr) => (
                      <React.Fragment key={index}>
                        <Flex justifyContent="space-between" py={2}>
                          <Box minW="50%">
                            <Text
                              whiteSpace="nowrap"
                              overflow="hidden"
                              textOverflow="ellipsis"
                              fontSize="small"
                              fontWeight="bold"
                            >
                              {item.label}
                            </Text>
                          </Box>
                          <Box minW="50%" textAlign="end">
                            <Text fontSize="small">{item.value}</Text>
                          </Box>
                        </Flex>
                        {index < arr.length - 1 && (
                          <Divider borderColor="gray.300" />
                        )}
                      </React.Fragment>
                    ))}
                </Box>

                <Accordion defaultIndex={[1]} allowMultiple width="100%" mt={4}>
                  {/* Hardcoded Virtual Tour panel */}
                  {/* <BuildingInfo
                    category={{
                      category: "Virtual Tour",
                      items: [
                        {
                          type: "virtualtour",
                          label: "Virtual Tour",
                          value: "https://my.matterport.com/show/?m=j4affnP1Ack"
                        }
                      ]
                    }}
                    onOpenVirtualTour={onOpenVirtualTour}
                  /> */}
                  
                  {/* Server-provided categories */}
                  {categories &&
                    categories?.map((cat) => {
                      return (
                        <BuildingInfo
                          category={cat}
                          onOpen={onOpen}
                          onOpenVideo={onOpenVideo}
                          onOpenVirtualTour={onOpenVirtualTour}
                        />
                      );
                    })}
                </Accordion>
              </TabPanel>

              {/* Room Info Tab */}
              <TabPanel h="52vh" maxH="52vh" overflow="auto">
                {roomsInfo?.map((room, index, arr) => {
                  const isExpandSection = expandedSections?.includes(room?.id);
                  return (
                    <React.Fragment key={index}>
                      <Box overflow="hidden" mb={4} mt={2}>
                        <HStack
                          justifyContent="space-between"
                          alignItems="center"
                          gap={4}
                        >
                          <Text
                            fontSize="md"
                            fontWeight="bold"
                            isTruncated
                            maxWidth="200px"
                            title={room.name}
                            color="#1D202B"
                          >
                            {room.name}
                          </Text>

                          <Button
                            rightIcon={
                              <ChevronDownIcon color="rgba(7, 131, 235, 1)" />
                            }
                            color="rgba(7, 131, 235, 1)"
                            bg="transparent"
                            fontSize="xs"
                            onClick={() => handleExpandSections(room?.id)}
                          >
                            Room Details
                          </Button>
                        </HStack>
                        {isExpandSection && (
                          <>
                            <VStack mb={4}>
                              {getRoomAttributes(room, "includedItems")
                                ?.key && (
                                <HStack
                                  w="full"
                                  borderBottom="1px solid #B7BFCB"
                                  p={1}
                                >
                                  <Text
                                    w="50%"
                                    fontSize="xs"
                                    fontWeight="bold"
                                    alignItems="flex-start"
                                  >
                                    Included Items
                                  </Text>
                                  <Text fontWeight={400} fontSize="xs">
                                    {
                                      getRoomAttributes(room, "includedItems")
                                        ?.value
                                    }
                                  </Text>
                                </HStack>
                              )}
                              {getRoomAttributes(room, "Typical Dimensions")
                                ?.key && (
                                <HStack
                                  w="full"
                                  borderBottom="1px solid #B7BFCB"
                                  p={1}
                                >
                                  <Text
                                    w="50%"
                                    fontSize="xs"
                                    fontWeight="bold"
                                    alignItems="flex-start"
                                  >
                                    {
                                      getRoomAttributes(
                                        room,
                                        "Typical Dimensions"
                                      )?.key
                                    }
                                  </Text>
                                  <Text fontWeight={400} fontSize="xs" p={1}>
                                    {
                                      getRoomAttributes(
                                        room,
                                        "Typical Dimensions"
                                      )?.value
                                    }
                                  </Text>
                                </HStack>
                              )}
                              {getRoomAttributes(
                                room,
                                "Recommended Carpet Size"
                              )?.value && (
                                <HStack w="full" p={1}>
                                  <Text
                                    w="50%"
                                    fontSize="xs"
                                    fontWeight="bold"
                                    alignItems="flex-start"
                                  >
                                    Recommended Carpet Size
                                  </Text>
                                  <Text fontWeight={400} fontSize="xs">
                                    {
                                      getRoomAttributes(
                                        room,
                                        "Recommended Carpet Size"
                                      )?.value
                                    }
                                  </Text>
                                </HStack>
                              )}
                            </VStack>
                            <Box
                              position="relative"
                              borderRadius="lg"
                              overflow="hidden"
                            >
                              <Image
                                src={room.thumbnail}
                                alt={room.name}
                                width="100%"
                                borderRadius="lg"
                                height={231}
                              />
                              <Button
                                leftIcon={
                                  <Image
                                    src={ExpandAlt}
                                    alt={building?.name}
                                    borderRadius="md"
                                    width="20px"
                                    height="20px"
                                    objectFit="cover"
                                  />
                                }
                                position="absolute"
                                bottom="4"
                                left="4"
                                bg="white"
                                color="rgba(7, 131, 235, 1)"
                                size="sm"
                                borderRadius="full"
                                onClick={() => handleRoomDetails(room)}
                              >
                                View Dimensions
                              </Button>
                              <Button
                                leftIcon={
                                  <Image
                                    src={RoomBlueSmall}
                                    alt={building?.name}
                                    borderRadius="md"
                                    width="20px"
                                    height="20px"
                                    objectFit="cover"
                                  />
                                }
                                as="a"
                                target="_blank"
                                href={`${process.env.REACT_APP_REDIRECT_ROOM_VIEW}/${configId}?id=${templateTokenUrl}&fromMap=true`}
                                position="absolute"
                                bottom="4"
                                right="3"
                                bg="white"
                                color="rgba(7, 131, 235, 1)"
                                size="sm"
                                borderRadius="full"
                              >
                                View Room
                              </Button>
                            </Box>
                          </>
                        )}
                      </Box>
                      {index < arr.length - 1 && (
                        <Divider borderColor="gray.300" />
                      )}
                    </React.Fragment>
                  );
                })}

                {/* Modal for Room Details */}
                {isRoomDetailsOpen && (
                  <Modal
                    isOpen={isRoomDetailsOpen}
                    onClose={handleRoomDetails}
                    size="4xl"
                    borderRadius="md"
                  >
                    <ModalOverlay />
                    <ModalContent>
                      <ModalHeader
                        isTruncated
                        maxWidth="90%"
                        title={selectedRoom?.name}
                        fontSize="2xl"
                      >
                        {selectedRoom.name}
                        <Text fontSize="sm" fontWeight={400} color="#3E4659CC">
                          Click to expand images
                        </Text>
                      </ModalHeader>
                      <ModalCloseButton />
                      <ModalBody borderRadius="2xl">
                        <HStack justifyContent="center">
                          <VStack>
                            <Image
                              src={dimensionUrl}
                              alt="Floor Plan"
                              width="39vw"
                              height="55vh"
                              objectFit="cover"
                            />
                          </VStack>
                        </HStack>
                        <Flex
                          mt={4}
                          bg="#D0D5DD"
                          borderRadius="3xl"
                          p={4}
                          alignItems="center"
                          justifyContent="space-between"
                          boxShadow="0px 2px 6px 0px rgba(0, 0, 0, 0.15)"
                        >
                          <HStack>
                            <Image
                              src={ThreeDRoom}
                              alt={building?.name}
                              borderRadius="md"
                              width="60px"
                              height="60px"
                              objectFit="cover"
                            />
                            <VStack alignItems="flex-start">
                              <Text fontSize="md" color="#272C3A">
                                See the room in 3D
                              </Text>
                              <Text fontSize="md" color="#272C3A">
                                Take custom measurements
                              </Text>
                            </VStack>
                          </HStack>
                          <Button
                            leftIcon={
                              <Image
                                src={RoomBlueSmall}
                                alt={building?.name}
                                borderRadius="md"
                                width="20px"
                                height="20px"
                                objectFit="cover"
                              />
                            }
                            as="a"
                            target="_blank"
                            href={`${process.env.REACT_APP_REDIRECT_ROOM_VIEW}/${configId}?id=${templateTokenUrl}&fromMap=true`}
                            borderRadius="full"
                            bg="#FFFFFFE5"
                            color="#0783EB"
                            boxShadow="0px 2px 6px 0px rgba(0, 0, 0, 0.15)"
                          >
                            View Room
                          </Button>
                        </Flex>
                      </ModalBody>
                    </ModalContent>
                  </Modal>
                )}
                <SharedModal
                  isOpen={isOpen}
                  onClose={onClose}
                  size="4xl"
                  height="90vh"
                >
                  <ImageCarousel urls={imageUrls} type="image" />
                </SharedModal>
                <SharedModal
                  isOpen={isOpenVideo}
                  onClose={onCloseVideo}
                  size="4xl"
                  height="90vh"
                >
                  <ImageCarousel urls={videoUrl} type="video" />
                </SharedModal>
                <Modal
                  isOpen={isOpenVirtualTour}
                  onClose={onCloseVirtualTour}
                  size="4xl"
                  isCentered
                >
                  <ModalOverlay />
                  <ModalContent height="90vh">
                    <ModalCloseButton 
                      bg="white" 
                      borderRadius="full" 
                      boxShadow="0px 2px 6px rgba(0, 0, 0, 0.2)"
                      zIndex="10"
                    />
                    <iframe
                      src={virtualTourUrl}
                      title="Virtual Tour"
                      width="100%"
                      height="100%"
                      frameBorder="0"
                      allowFullScreen
                    />
                  </ModalContent>
                </Modal>
              </TabPanel>
            </TabPanels>
          </Tabs>
        </VStack>
      )}
    </Box>
  );
};

export default Sidebar;
